var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"catalogs-list"},[_c('b-card',[_c('div',{staticClass:"p-2 d-flex align-items-center justify-content-between"},[_c('h1',{staticClass:"m-0 page-title"},[_vm._v(_vm._s(_vm.$t("g.catalogs.catalogs")))]),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-link',{staticClass:"btn btn-primary",attrs:{"to":"/catalog/add-catalog","role":"button"}},[_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"PlusIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.$t("g.catalogs.suggest_catalog"))+" ")],1)],1)]),_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"my-1 px-2",staticStyle:{"gap":"10px"}},[_c('b-col',{attrs:{"cols":"12","sm":"auto"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('search_placeholder')},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('b-col',[(_vm.searchTerm)?_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.clearSearch}},[_vm._v(" "+_vm._s(_vm.$t("clear_search"))+" ")]):_vm._e(),(_vm.permissions.catalogs.store)?_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.searchHandler}},[_vm._v(" "+_vm._s(_vm.$t("search"))+" ")]):_vm._e()],1)],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.tableHeader,"rows":_vm.tableBody,"styleClass":"table-custom-style vgt-table striped condensed","pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label === 'main_image')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.main_image"))+" ")]):(props.column.label === 'category')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.category"))+" ")]):(props.column.label === 'catalog')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.catalog"))+" ")]):(props.column.label === 'brand')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.brand"))+" ")]):(
            props.column.label === 'action' && _vm.permissions.catalogs.show
          )?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.action"))+" ")]):(props.column.label === 'name')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.name"))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'main_image')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{attrs:{"variant":"info","src":props.row.main_image}})],1):(props.column.field === '#')?_c('span',{staticClass:"text-nowrap custom-toggle"},[_vm._v(" "+_vm._s(props.row.index)+" ")]):(props.column.field === 'category')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.row.category)+" ")]):(
            props.column.field === 'action' && _vm.permissions.catalogs.show
          )?_c('span',[_c('span',[_c('router-link',{attrs:{"to":{ name: 'ShowCatalog', params: { id: props.row.slug } }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EyeIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.catalogs.show")))])],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(_vm._s(_vm.$t("g.no_data")))]),_c('template',{slot:"pagination-bottom"},[_c('div',{staticClass:"mt-2 d-flex justify-content-center flex-wrap"},[_c('b-pagination',{attrs:{"size":"md","align":"center","total-rows":_vm.dataMeta.total,"per-page":_vm.dataMeta.per_page},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }