<template>
  <Loading v-if="isLoading" />
  <main v-else>
    <catalogs
      :dataMeta="catalogMeta"
      :table-body="catalog"
      @searchForCatalog="searchForCatalog"
      @getCatalogList="getCatalogList"
    />
    <overlay-loading :isLoading="overlayLoading" />
  </main>
</template>

<script>
import Loading from "@/components/shared/loading/loading.vue";
import Catalogs from "@/components/catalogs/list/catalogs.vue";
import OverlayLoading from "@/components/shared/OverlayComponent";

export default {
  name: "catalogsList",
  components: {
    Loading,
    Catalogs,
    OverlayLoading,
  },
  data() {
    return {
      isLoading: true,
      overlayLoading: false,
      isError: false,
      catalog: [],
      tableBody: [],
    };
  },
  beforeMount() {
    this.getCatalogList();
  },
  methods: {
    async getCatalogList(page, search) {
      try {
        page ? (this.overlayLoading = true) : (this.isLoading = true);
        const response = search
          ? await this.$http.get("vendor/catalogs", {
              headers: {
                "X-Page-Size": 12,
              },
              params: {
                page: page || 1,
                search,
              },
            })
          : await this.$http.get("vendor/catalogs", {
              headers: {
                "X-Page-Size": 12,
              },
              params: {
                page: page || 1,
              },
            });
        if (response.status === 200) {
          const { data } = response.data;
          this.catalogMeta = response.data.meta;
          this.catalog = [];
          data.forEach((item, index) => {
            const catalog = {
              index: index + 1,
              id: item.id,
              country: item?.made_in,
              name: item?.name,
              slug: item.slug,
              catalog: item?.name,
              brand: item?.brand?.name,
              main_image: item?.main_image?.path,
              vendor: item?.name,
              category: item?.category.name,
            };
            this.catalog.push(catalog);
          });
        }
      } catch (error) {
        this.isError = true;
      } finally {
        page ? (this.overlayLoading = false) : (this.isLoading = false);
      }
    },
    async searchForCatalog(search) {
      try {
        this.overlayLoading = true;
        const res = search
          ? await this.$http.get("vendor/catalogs", { params: { search } })
          : await this.$http.get("vendor/catalogs");
        this.catalogMeta = res.data.meta;
        this.catalog = [];
        res.data.data.forEach((item, index) => {
          const catalog = {
            index: index + 1,
            id: item.id,
            country: item?.made_in,
            name: item?.name,
            slug: item.slug,
            catalog: item?.name,
            brand: item?.brand?.name,
            main_image: item?.main_image?.path,
            vendor: item?.name,
            category: item?.category.name,
          };
          this.catalog.push(catalog);
        });
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./style.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
