<template>
  <section class="catalogs-list">
    <b-card>
      <div class="p-2 d-flex align-items-center justify-content-between">
        <h1 class="m-0 page-title">{{ $t("g.catalogs.catalogs") }}</h1>
        <div class="d-flex align-items-center">
          <b-link
            to="/catalog/add-catalog"
            role="button"
            class="btn btn-primary"
          >
            <feather-icon icon="PlusIcon" class="text-white" size="16" />
            {{ $t("g.catalogs.suggest_catalog") }}
          </b-link>
        </div>
      </div>

      <form @submit.prevent>
        <b-row class="my-1 px-2" style="gap: 10px">
          <b-col cols="12" sm="auto">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('search_placeholder')"
            />
          </b-col>
          <b-col>
            <b-button
              variant="outline-secondary"
              @click="clearSearch"
              class="mx-1"
              v-if="searchTerm"
            >
              {{ $t("clear_search") }}
            </b-button>
            <b-button
              v-if="permissions.catalogs.store"
              @click="searchHandler"
              variant="primary"
              type="submit"
            >
              {{ $t("search") }}
            </b-button>
          </b-col>
        </b-row>
      </form>

      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'main_image'" class="text-nowrap">
            {{ $t("g.main_image") }}
          </span>
          <span
            v-else-if="props.column.label === 'category'"
            class="text-nowrap"
          >
            {{ $t("g.category") }}
          </span>
          <span
            v-else-if="props.column.label === 'catalog'"
            class="text-nowrap"
          >
            {{ $t("g.catalog") }}
          </span>
          <span v-else-if="props.column.label === 'brand'" class="text-nowrap">
            {{ $t("g.brand") }}
          </span>
          <span
            v-else-if="
              props.column.label === 'action' && permissions.catalogs.show
            "
            class="text-nowrap"
          >
            {{ $t("g.action") }}
          </span>
          <span v-else-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("g.name") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'main_image'" class="text-nowrap">
            <b-avatar variant="info" :src="props.row.main_image" />
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>
          <span
            v-else-if="props.column.field === 'category'"
            class="text-nowrap"
          >
            {{ props.row.category }}
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="
              props.column.field === 'action' && permissions.catalogs.show
            "
          >
            <span>
              <router-link
                :to="{ name: 'ShowCatalog', params: { id: props.row.slug } }"
              >
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span>{{ $t("g.catalogs.show") }}</span>
              </router-link>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <div slot="emptystate">{{ $t("g.no_data") }}</div>

        <!-- pagination -->
        <template slot="pagination-bottom">
          <div class="mt-2 d-flex justify-content-center flex-wrap">
            <b-pagination
              size="md"
              align="center"
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="dataMeta.per_page"
            >
            </b-pagination>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </section>
</template>

<script>
import {
  BPagination,
  BCard,
  BDropdownItem,
  BDropdown,
  BLink,
  BAvatar,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

export default {
  name: "catalogs",
  components: {
    VueGoodTable,
    BPagination,
    BCard,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BLink,
    BFormInput,
    BRow,
    BCol,
    BButton,
  },

  props: {
    tableBody: {
      type: Array,
      default: () => [],
    },
    dataMeta: {
      type: Object,
    },
  },
  computed: {
    permissions() {
      return this.$store?.getters?.getUserPermission;
    },
  },
  data() {
    return {
      overlayLoading: false,
      searchTerm: "",
      pageLength: 12,
      currentPage: 1,
      plusIcon: require("@/assets/images/icons/ic_round-add.svg"),
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "main_image",
          field: "main_image",
        },
        {
          label: "name",
          field: "name",
        },
        {
          label: "category",
          field: "category",
        },
        {
          label: "brand",
          field: "brand",
        },
        {
          label: "action",
          field: "action",
        },
      ],
    };
  },
  methods: {
    searchHandler() {
      this.$emit("searchForCatalog", this.searchTerm);
    },
    clearSearch() {
      this.searchTerm = "";
      this.$emit("searchForCatalog", "");
    },
  },
  watch: {
    currentPage(newValue) {
      this.$emit("getCatalogList", newValue, this.searchTerm);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
